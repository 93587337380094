











import { computed, defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { getVideoUrl } from '~/modules/amplience/helpers/getVideoUrl';

export default defineComponent({
  name: 'VideoAmplience',
  props: {
    videoOrientation: {
      type: String,
    },
    caption: {
      type: String,
    },
    video: {
      type: Object,
    },
    profileName: {
      type: String,
    },
  },
  setup(props) {
    const videoContent = ref(null);
    useFetch(async () => {
      const response = await fetch(getVideoUrl(props.video));
      videoContent.value = await response.text();
    });

    return {
      videoContent,
      orientationClass: computed(() => props.videoOrientation?.toLowerCase() || ''),
    };
  },
});
